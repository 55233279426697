footer {
  background-color: #356473;
  padding: 20px;
  margin-top: 45px;
}
a {
  color: inherit;
  text-decoration: none;
}
.contents {
  max-width: 900px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: white;
  margin-bottom: 25px;
}

.contents p {
  margin: 10px 0;
}
.box a {
  color: #fff;
}
@media screen and (max-width: 460px) {
  .contents p {
    font-size: 14px;
  }
}
.social {
  max-width: 320px;
  text-align: center;
  margin: auto;
  color: white;
}

.social p {
  margin-left: 0;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 0;
  margin: 18px 0;
}

.icons i {
  font-size: 25px;
}
