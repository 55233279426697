.error__container {
  display: flex;
  align-items: center;
  gap: var(--heading-1);
  justify-content: space-between;
}
.error__button1 {
  margin-top: 50px;
  color: #fff;
}
.error__button2 {
  border: 2px solid #0397d6 !important ;
  background-color: transparent !important;
  color: #0397d6 !important;
}
.error__container :nth-child(1) {
  flex-basis: 40%;
}
.error__image {
  flex-basis: 60%;
}
/* to undo global hover styling */
.error__button2:hover {
  background: initial;
}
@media screen and (max-width: 768px) {
  .error__container {
    flex-direction: column;
  }
  .error__image {
    order: -1;
  }
}
