.admin-upload__container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .1);
    inset: 0;
    max-width: 100%;
}

.admin-upload {
    width: 90%;
    height: 90%;
    background-color: #fff;
    padding: 20px 50px;
}

.admin-upload div {
    margin-block: 20px;
}