.single_post_container {
    max-width: 100%;
    /* margin: auto; */
}

.single_image_container {
    max-width: 100%;
    height: 550px;
    margin: auto;
}

.single_image_container img {
    width: 100%;
    height: 100%;
}

.content {
    text-align: justify;
    margin: 30px 0;
    font-size: 17px;
    line-height: 30px;
}

.heading {
    text-align: center;
}

.hero_text {
    text-align: center;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.back_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

/* .back_button:hover {
    background-color: #fefefe;
    border: 1.5px solid black;
    color: black;
} */

.singlePost_details {
    max-width: 950px;
    margin: 25px auto;
}

.author_details {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 15px 0;
}

.author_details p {
    font-weight: medium;
}

.heading p {
    font-weight: medium;
}

.author_image {
    width: 50px;
    height: 50px;
    border: 3px solid gray;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media screen and (max-width: 600px) {
    .single_post_container {
        width: 100%;
    }

    .single_image_container {
        max-width: 100%;
        height: 350px;
    }

    .singlePost_details {
        padding: 0 15px;
    }

    .hero_text {
        text-align: center;
        position: absolute;
        top: 45%;
        width: 90%;
    }
}