.section_wrapper {
  height: 40rem;
}

.header {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_container {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  max-height: 35rem;
}

.section_container_swap {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  max-height: 35rem;
}

.section_message {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 5rem;
}

.section_img {
  flex-basis: 50%;
  height: 100%;
}

.section_img img {
  max-height: 33rem;
  width: 100%;
}

.msgBody {
  flex-basis: 70%;
}

.msgBtn {
  flex-basis: 30%;
}

/* Mobile Responsiveness */
@media screen and (max-width: 650px) {
  .section_wrapper {
    max-height: max-content;
  }

  .section_container {
    flex-direction: column;
    height: max-content;
  }

  .section_message {
    height: max-content;
    padding: 3rem;
  }

  .section_img {
    padding: 3rem;
  }

  .section_message h1 {
    line-height: 3.5rem;
    text-align: center;
  }
}