/* semantic styles for specific elements */
.disable-margin-top {
  margin-top: 0 !important;
}

.disable-margin-block {
  margin-block: 0 !important;
}

.center {
  text-align: center;
}

.normal-weight {
  font-weight: 500;
}

.full-width {
  width: 100% !important;
}